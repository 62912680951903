import React from 'react';
import './App.css';
import EventPage from './components/EventPage';

function App() {
  return (
    <div className="Wrapper">
      <div className="App">
        <EventPage />
      </div>
    </div>
  );
}

export default App;
