import { useLocation, useParams } from 'react-router-dom';
import {
  addDoc,
  arrayUnion,
  collection,
  doc,
  setDoc,
  updateDoc,
} from 'firebase/firestore';
import { Button } from '@mui/material';
import { useEffect } from 'react';
import { firestore } from './utils/firebase';

function decodeBase64(encodedData: string): string | undefined {
  try {
    const decodedData = atob(encodedData);
    return decodeURIComponent(escape(decodedData));
  } catch (error) {
    console.error('Base64 디코딩 중 에러 발생:', error);
    // 에러가 발생하면 빈 문자열을 반환하거나, 에러를 더 상위로 던질 수도 있습니다.
    // 여기에서는 빈 문자열을 반환하는 방식을 선택했습니다.
    return undefined;
  }
}

function Feedback() {
  const { data } = useParams();

  const decoded = decodeBase64(data ?? '');

  const confirm = async () => {
    try {
      const docRef = doc(firestore, 'stockboy_feedback', date);
      await setDoc(docRef, {}, { merge: true });
      await updateDoc(docRef, {
        applicants: arrayUnion(email),
      });
      alert('성공적으로 신청이 완료되었습니다. 내일도 리포트를 보내드릴게요!');

      window.location.href = 'https://forms.gle/5eFpNV1nnWaLciez8';
    } catch (e) {
      console.error('Error adding document: ', e);
    }
  };

  useEffect(() => {
    confirm();
  }, []);

  if (!decoded) {
    alert('잘못된 접근입니다');

    return <div />;
  }
  const { name, email, date } = JSON.parse(decoded);

  return <div />;
  // return (
  //   <div
  //     style={{
  //       padding: '24px',
  //       justifyContent: 'center',
  //       display: 'flex',
  //       flexDirection: 'column',
  //       alignItems: 'center',
  //     }}
  //   >
  //     {name} 님
  //     <br />
  //     {date}에 받아보신 리포트는 만족하셨나요?
  //     <br />
  //     다음 리포트도 받아보고 싶으시다면
  //     <br />
  //     <br />
  //     <br />
  //     <Button
  //       variant="contained"
  //       onClick={async () => {
  //         try {
  //           const docRef = doc(firestore, 'stockboy_feedback', date);
  //           await setDoc(docRef, {}, { merge: true });
  //           await updateDoc(docRef, {
  //             applicants: arrayUnion(email),
  //           });
  //           alert(
  //             '성공적으로 신청이 완료되었습니다. 내일도 리포트를 보내드릴게요!',
  //           );
  //         } catch (e) {
  //           console.error('Error adding document: ', e);
  //         }
  //       }}
  //     >
  //       내일도 받기
  //     </Button>
  //   </div>
  // );
}
export default Feedback;
