import {
  Box,
  InputAdornment,
  Link,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import axios from 'axios';

import { NumericFormat, NumericFormatProps } from 'react-number-format';
import { IMaskInput } from 'react-imask';

import React, { useEffect, useMemo, useRef, useState } from 'react';
import { CheckCircle, CheckCircleOutline } from '@mui/icons-material';
import { logEvent } from 'firebase/analytics';
import { useSearchParams } from 'react-router-dom';
// import shareButton from '../assets/share_button.svg';
import accept_button from '../assets/accept_button.svg';
import accept_disabled_button from '../assets/accept_disabled_button.svg';
import accept_button_en from '../assets/accept_button_en.svg';
import accept_disabled_button_en from '../assets/accept_disabled_button_en.svg';
import update_button from '../assets/update_button.svg';
import update_disabled_button from '../assets/update_disabled_button.svg';
import update_button_en from '../assets/update_button_en.svg';
import update_disabled_button_en from '../assets/update_disabled_button_en.svg';
import locale_us_active_kr from '../assets/locale_us_active_kr.svg';
import locale_kr_active_kr from '../assets/locale_kr_active_kr.svg';
import locale_us_default_kr from '../assets/locale_us_default_kr.svg';
import locale_kr_default_kr from '../assets/locale_kr_default_kr.svg';
import locale_us_active_en from '../assets/locale_us_active_en.svg';
import locale_kr_active_en from '../assets/locale_kr_active_en.svg';
import locale_us_default_en from '../assets/locale_us_default_en.svg';
import locale_kr_default_en from '../assets/locale_kr_default_en.svg';
import sumxLogoSvg from '../assets/sumx_logo.svg';
import deleteIcon from '../assets/delete_icon.svg';

import SearchContainer from './SearchContainer';
import { analytics } from '../utils/firebase';
import LoadingComponent from './LoadingComponent';

const API_BASE_URL = `https://api.chat.boolio.ai`;
// const API_BASE_URL = `http://localhost:8093`;

interface GetPortfolioResponse {
  portfolioName: string;
  portfolioItems: Array<Portfolio>;
}
interface Portfolio {
  name: string;
  region: string;
  ticker: string;
  currency: string;
  quantity?: number;
  averagePrice?: number;
}

function decodeBase64(encodedData: string): string | undefined {
  try {
    const decodedData = atob(encodedData);
    return decodeURIComponent(escape(decodedData));
  } catch (error) {
    console.error('Base64 디코딩 중 에러 발생:', error);
    // 에러가 발생하면 빈 문자열을 반환하거나, 에러를 더 상위로 던질 수도 있습니다.
    // 여기에서는 빈 문자열을 반환하는 방식을 선택했습니다.
    return undefined;
  }
}

const Divider = () => (
  <div
    style={{
      background: '#C4C4C4',
      width: '100%',
      height: '1px',
      marginTop: '30px',
      marginBottom: '30px',
    }}
  ></div>
);

const defaultPortfolio = [
  {
    name: 'NVIDIA',
    region: 'US',
    ticker: 'NVDA',
    currency: 'USD',
    quantity: 10,
    averagePrice: 780,
  },
  {
    name: 'TESLA',
    region: 'US',
    ticker: 'TSLA',
    currency: 'USD',
    quantity: 10,
    averagePrice: 140,
  },
  {
    name: 'MICROSOFT',
    region: 'US',
    ticker: 'MSFT',
    currency: 'USD',
    quantity: 10,
    averagePrice: 320,
  },
  {
    name: 'APPLE',
    region: 'US',
    ticker: 'AAPL',
    currency: 'USD',
    quantity: 10,
    averagePrice: 80,
  },
];
function EventPage() {
  // const [portfolioList, setPortfolioList] = useState<Array<Portfolio>>([]);
  // const [email, setEmail] = useState<string>('');
  // const [isValidEmail, setIsValidEmail] = useState<boolean>(false);
  // const [phoneNumber, setPhoneNumber] = useState<string>('');
  // const [portfolioName, setPortfolioName] = useState<string>('');
  // const [locale, setLocale] = useState<'US' | 'KR'>('US');
  // const [localeSelected, setLocaleSelected] = useState<boolean>(false);
  // const [subscriberName, setSubscriberName] = useState<string>('');
  // const [isInit, setIsInit] = useState<boolean>(false);
  // const [isComplete, setIsComplete] = useState<boolean>(false);
  // const isInitialMount = useRef(true);
  // const [selectedImages, setSelectedImages] = useState<File[]>([]);

  // const [isSending, setIsSending] = useState<boolean>(false);
  // const tracker = new URLSearchParams(document.location.search).get('tracker');

  // const alwayOpenTrackers = ['beta1_waitlist'];

  const [searchParams] = useSearchParams();
  const data = searchParams.get('data');
  // const parsedData: any = {};
  // const isLocaleKorean = navigator.language.includes('ko');


  if (data) {
    window.location.href = `https://stockportfolio.boolio.ai/update?data=${data}`;
     
    // const decoded = decodeBase64(data ?? '');

    
    // parsedData = JSON.parse(decoded ?? '{}');
  }else{
    window.location.href = `https://stockportfolio.boolio.ai`;

  }

  return <div />;
}


//   /// 오픈하려면 아래 boolean을 false로, 마감하려면 true로 변경하면 된다
//   const isClosed = false && !alwayOpenTrackers.includes(tracker ?? '');

//   useEffect(() => {
//     if (!isInit) {
//       setIsInit(true);
//       logEvent(analytics, 'page_view', {
//         referrer: document.referrer,
//         tracker,
//       });
//     }

//     if (parsedData.portfolioId) {


//       // axios
//       //   .get<GetPortfolioResponse>(
//       //     `${API_BASE_URL}/sumx/portfolio/${parsedData.portfolioId}`,
//       //   )
//       //   .then((v) => {
//       //     setPortfolioName(v.data.portfolioName);
//       //     setPortfolioList(v.data.portfolioItems);
//       //   });
//     } else {
//       const localStoragePortfolioListString =
//         localStorage.getItem('portfolioList');
//       if (localStoragePortfolioListString) {
//         setPortfolioList(JSON.parse(localStoragePortfolioListString));
//       } else {
//         setPortfolioList(defaultPortfolio);
//       }
//     }
//   }, []);

//   useEffect(() => {
//     if (isInitialMount.current) {
//       isInitialMount.current = false;
//     } else {
//       localStorage.setItem('portfolioList', JSON.stringify(portfolioList));
//     }
//   }, [portfolioList]);

//   const isValid: boolean = portfolioList.reduce<boolean>(
//     (accumulator, { quantity, averagePrice }) => {
//       return (
//         accumulator &&
//         quantity !== undefined &&
//         quantity > 0 &&
//         averagePrice !== undefined &&
//         averagePrice > 0
//       );
//     },
//     true,
//   );

//   const disabled = parsedData.portfolioId
//     ? portfolioList.length === 0 || !isValid
//     : isClosed
//     ? !isComplete || subscriberName === '' || email === ''
//     : (portfolioList.length === 0 && selectedImages.length === 0) ||
//       !isValid ||
//       !isComplete ||
//       subscriberName === '' ||
//       email === '' ||
//       (email && !isValidEmail) ||
//       isSending;

//   const submit = async () => {
//     setIsSending(true);

//     if (parsedData.portfolioId) {
//       // EDIT
//       await axios
//         .put(`${API_BASE_URL}/sumx/portfolio/${parsedData.portfolioId}`, {
//           locale,
//           email,
//           subscriberName,
//           portfolioName,
//           items: portfolioList,
//         })
//         .then(() => {
//           alert('포트폴리오를 업데이트 했어요');
//         });
//     } else {
//       // 등록
//       await axios
//         .post(`${API_BASE_URL}/sumx/portfolio/`, {
//           locale,
//           email,
//           subscriberName,
//           portfolioName,
//           items: portfolioList,
//         })
//         .then(({ data }: any) => {
//           const subscriberCreated = data.subscriberCreated;
//           alert(
//             `포트폴리오가 제출되었습니다.\n${email}에서 sumx가 보낸 메일을 확인해주세요.`,
//           );
//           window.location.href = `https://sumx.boolio.ai/`;
//         });
//     }
//     setPortfolioList([]);
//     setIsComplete(false);
//     setEmail('');
//     setPortfolioName('');
//     setPhoneNumber('');
//     setSubscriberName('');
//     setPortfolioList([]);
//     logEvent(analytics, 'complete', {
//       referrer: document.referrer,
//       tracker,
//     });
//     setIsSending(false);
//   };

//   return useMemo(
//     () => (
//       <Stack px="24px" py="60px">
//         {isClosed ? (
//           <Stack>
//             <Typography
//               fontSize="20px"
//               color="#212A34"
//               fontWeight="bold"
//               mb="8px"
//             >
//               종목 진단 신청이 마감되었습니다.
//             </Typography>
//             <Typography
//               mt="8px"
//               width="100%"
//               fontSize="16px"
//               color="#646F7C"
//               whiteSpace="pre-line"
//               lineHeight={1.5}
//             >
//               {`감사합니다. 종목 진단 신청이 모두 마감되었습니다.\n다음 기회에 참여하고 싶으시다면, 아래 이름과 연락처를 남겨주세요.`}
//             </Typography>
//           </Stack>
//         ) : (
//           <>
//             <div style={{ paddingBottom: '30px' }}>
//               <img src={sumxLogoSvg} alt="logo" />
//             </div>
//             {!parsedData.portfolioId && (
//               <>
//                 <Stack pb="30px">
//                   <Typography
//                     fontSize="32px"
//                     color="#121212"
//                     fontWeight="700"
//                     lineHeight={1.3}
//                   >
//                     {isLocaleKorean ? (
//                       <>
//                         <div>매일 리뷰할</div>
//                         <div>미국주식 포트폴리오를</div>
//                         <div>알려주세요</div>
//                       </>
//                     ) : (
//                       <>
//                         <div>Register</div>
//                         <div>your stock portfolio</div>
//                       </>
//                     )}
//                   </Typography>
//                   <Typography color="#646F7C" mt="20px">
//                     {isLocaleKorean ? (
//                       <>
//                         <div>
//                           하나의 포트폴리오에 20개의 종목을 추가할 수 있습니다.
//                         </div>
//                         <div>이 내용은 언제든지 변경할 수 있어요.</div>
//                       </>
//                     ) : (
//                       <>
//                         <div>You can add 20 stocks to your portfolio.</div>
//                         <div>
//                           You can change your registered portfolio at any time.
//                         </div>
//                       </>
//                     )}
//                   </Typography>
//                 </Stack>
//                 <Stack flexDirection={['column', 'row']} gap="10px">
//                   <img
//                     style={{ cursor: 'pointer', flex: 1, maxHeight: '80px' }}
//                     src={
//                       isLocaleKorean
//                         ? localeSelected && locale === 'KR'
//                           ? locale_kr_active_kr
//                           : locale_kr_default_kr
//                         : localeSelected && locale === 'KR'
//                         ? locale_kr_active_en
//                         : locale_kr_default_en
//                     }
//                     alt="localeLeft"
//                     onClick={() => {
//                       setLocale('KR');
//                       setLocaleSelected(true);
//                     }}
//                   />
//                   <img
//                     style={{ cursor: 'pointer', flex: 1, maxHeight: '80px' }}
//                     src={
//                       isLocaleKorean
//                         ? localeSelected && locale === 'US'
//                           ? locale_us_active_kr
//                           : locale_us_default_kr
//                         : localeSelected && locale === 'US'
//                         ? locale_us_active_en
//                         : locale_us_default_en
//                     }
//                     alt="localeRight"
//                     onClick={() => {
//                       setLocale('US');
//                       setLocaleSelected(true);
//                     }}
//                   />
//                 </Stack>
//                 <Divider />
//               </>
//             )}
//             <Box bgcolor="white">
//               <TextField
//                 placeholder={
//                   isLocaleKorean
//                     ? '(선택) 포트폴리오 이름'
//                     : '(Optional) Portfolio name'
//                 }
//                 variant="outlined"
//                 sx={{ width: '100%' }}
//                 value={portfolioName}
//                 onChange={(e) => {
//                   setPortfolioName(e.target.value);
//                 }}
//               />
//             </Box>
//             <PortfolioInput
//               isLocaleKorean={isLocaleKorean}
//               portfolioId={parsedData.portfolioId}
//               portfolioList={portfolioList}
//               setPortfolioList={(v) => setPortfolioList(v)}
//               selectedImages={selectedImages}
//               setSelectedImages={(v) => setSelectedImages(v)}
//             />
//             <Divider />
//           </>
//         )}
//         {!parsedData.portfolioId && (
//           <EmailInput
//             isLocaleKorean={isLocaleKorean}
//             name={subscriberName}
//             setName={setSubscriberName}
//             email={email}
//             setEmail={setEmail}
//             isValidEmail={isValidEmail}
//             setIsValidEmail={setIsValidEmail}
//             phoneNumber={phoneNumber}
//             setPhoneNumber={setPhoneNumber}
//           />
//         )}
//         <Stack>
//           {!parsedData.portfolioId && (
//             <Stack direction="row" my="20px" alignItems="center">
//               {isComplete ? (
//                 <CheckCircle
//                   style={{ cursor: 'pointer' }}
//                   onClick={() => setIsComplete(false)}
//                 />
//               ) : (
//                 <CheckCircleOutline
//                   style={{ cursor: 'pointer' }}
//                   onClick={() => setIsComplete(true)}
//                 />
//               )}
//               <Link
//                 title="개인정보 수집 및 이용에 동의합니다."
//                 href="https://doomoolmori.notion.site/3f23428f775140fb8ab937f8b83aa9c3?pvs=4"
//                 flex={1}
//                 sx={{
//                   textDecoration: 'underline',
//                   color: '#212A34',
//                   fontFamily: 'inherit',
//                 }}
//                 target="_blank"
//               >
//                 <Typography mx="8px">
//                   {isLocaleKorean
//                     ? '개인정보 수집 및 이용에 동의합니다.'
//                     : 'I agree to the collection and use of my personal information.'}
//                 </Typography>
//               </Link>
//             </Stack>
//           )}

//           {isSending && <LoadingComponent height="60px" />}
//           <img
//             src={
//               disabled
//                 ? isLocaleKorean
//                   ? !parsedData.portfolioId
//                     ? accept_disabled_button
//                     : update_disabled_button
//                   : !parsedData.portfolioId
//                   ? accept_disabled_button_en
//                   : update_disabled_button_en
//                 : isLocaleKorean
//                 ? !parsedData.portfolioId
//                   ? accept_button
//                   : update_button
//                 : !parsedData.portfolioId
//                 ? accept_button_en
//                 : update_button_en
//             }
//             width="100%"
//             alt="acceptButton"
//             style={{
//               cursor: 'pointer',
//               visibility: isSending ? 'hidden' : 'inherit',
//             }}
//             onClick={disabled ? undefined : submit}
//           />
//         </Stack>
//       </Stack>
//     ),
//     [
//       portfolioList,
//       subscriberName,
//       email,
//       locale,
//       localeSelected,
//       portfolioName,
//       isComplete,
//       disabled,
//       phoneNumber,
//       selectedImages,
//       isValidEmail,
//     ],
//   );
// }

// function PortfolioInput({
//   isLocaleKorean,
//   portfolioId,
//   portfolioList,
//   setPortfolioList,
//   selectedImages,
//   setSelectedImages,
// }: {
//   isLocaleKorean: boolean;
//   portfolioId: string | null;
//   portfolioList: Array<Portfolio>;
//   setPortfolioList: (v: Array<Portfolio>) => void;
//   selectedImages: Array<File>;
//   setSelectedImages: (v: Array<File>) => void;
// }) {
//   const hiddenFileInput = useRef<HTMLInputElement>(null);

//   return (
//     <Stack>
//       {portfolioList.length > 0 && (
//         <Typography
//           sx={{
//             color: '#A7AEB9',
//             fontSize: '14px',
//           }}
//         >
//           {isLocaleKorean
//             ? `총 ${portfolioList.length}종목`
//             : `Total of ${portfolioList.length} items`}
//         </Typography>
//       )}
//       {portfolioList.map((portfolio, i) => (
//         <PortfolioItem
//           key={`PortfolioItem-${portfolio.ticker}`}
//           index={i}
//           isLocaleKorean={isLocaleKorean}
//           portfolio={portfolio}
//           onUpdateCurrency={(v) => {
//             const newP = [...portfolioList];
//             const index = newP.findIndex((v) => v.ticker === portfolio.ticker);
//             if (index < 0) return;
//             newP[index].currency = v;
//             setPortfolioList(newP);
//           }}
//           onUpdateQuantity={(v) => {
//             const newP = [...portfolioList];
//             const index = newP.findIndex((v) => v.ticker === portfolio.ticker);
//             if (index < 0) return;
//             newP[index].quantity = v;
//             setPortfolioList(newP);
//           }}
//           onUpdateAveragePrice={(v) => {
//             const newP = [...portfolioList];
//             const index = newP.findIndex((v) => v.ticker === portfolio.ticker);
//             if (index < 0) return;

//             newP[index].averagePrice = v;
//             setPortfolioList(newP);
//           }}
//           onDelete={() => {
//             setPortfolioList(
//               portfolioList.filter((v) => v.ticker !== portfolio.ticker),
//             );
//           }}
//         />
//       ))}

//       <SearchContainer
//         isLocaleKorean={isLocaleKorean}
//         onClose={(v) => {
//           if (
//             v &&
//             portfolioList.findIndex(
//               (portfolio) => portfolio.ticker === v.ticker,
//             ) < 0
//           ) {
//             setPortfolioList([
//               ...portfolioList,
//               {
//                 name: v.companyLocalName ?? v.companyName,
//                 ticker: v.ticker,
//                 currency: v.region === 'KR' ? 'KRW' : 'USD',
//                 region: v.region, // regions[v.nationCode],
//               },
//             ]);
//           }
//         }}
//       />
//     </Stack>
//   );
// }

// function PortfolioItem({
//   index,
//   isLocaleKorean,
//   portfolio,
//   onDelete,
//   onUpdateQuantity,
//   onUpdateAveragePrice,
//   onUpdateCurrency,
// }: {
//   index: number;
//   isLocaleKorean: boolean;
//   portfolio: Portfolio;
//   onDelete: () => void;
//   onUpdateQuantity: (v: number) => void;
//   onUpdateAveragePrice: (v: number) => void;
//   onUpdateCurrency: (v: string) => void;
// }) {
//   const regex = /^\d+(\.\d+)?$/;
//   // /^(d*.?d*)$/g

//   return (
//     <Stack mb="14px" py="8px">
//       <Stack paddingY="2px" direction="row">
//         <Typography
//           flex={1}
//           sx={{
//             display: '-webkit-box',
//             overflow: 'hidden',
//             WebkitBoxOrient: 'vertical',
//             WebkitLineClamp: 1,
//             marginRight: '16px',
//             color: '#212A34',
//           }}
//         >
//           {portfolio.ticker} {portfolio.name}
//         </Typography>

//         <img
//           src={deleteIcon}
//           alt="deleteIcon"
//           onClick={onDelete}
//           onKeyPress={() => {
//             //
//           }}
//         />
//       </Stack>
//       <Stack paddingY="2px" direction="row" alignItems="center">
//         <Typography
//           width="72px"
//           sx={{
//             display: '-webkit-box',
//             overflow: 'hidden',
//             WebkitBoxOrient: 'vertical',
//             WebkitLineClamp: 1,
//             marginRight: '2px',
//             color: '#646F7C',
//           }}
//         >
//           {isLocaleKorean ? '보유 수량' : 'Quantity'}
//         </Typography>
//         <TextField
//           inputProps={{
//             min: 0,
//             style: { textAlign: 'end' },
//             inputMode: 'decimal',
//             pattern: '^(d*.?d*)$',
//           }}
//           InputProps={{ inputComponent: NumericFormatCustom as any }}
//           placeholder="0"
//           variant="outlined"
//           sx={{ textAlign: 'end', flex: 1 }}
//           style={{ backgroundColor: 'white' }}
//           value={portfolio.quantity}
//           onChange={(e) => {
//             const inputValue = e.target.value;
//             if (regex.test(inputValue) || inputValue === '') {
//               onUpdateQuantity(Number(inputValue));
//             }
//           }}
//         />
//       </Stack>
//       <Stack paddingY="2px" direction="row">
//         <Typography
//           width="72px"
//           sx={{
//             overflow: 'hidden',
//             marginRight: '2px',
//             whiteSpace: 'pre-wrap',
//             color: '#646F7C',
//           }}
//         >
//           {isLocaleKorean ? `평균\n매수단가` : 'Average'}
//         </Typography>
//         <Select
//           style={{ backgroundColor: 'white' }}
//           value={portfolio.currency}
//           onChange={(e) => onUpdateCurrency(e.target.value)}
//           sx={{
//             marginRight: '4px',
//           }}
//         >
//           <MenuItem value="USD">
//             <em>{isLocaleKorean ? '달러' : 'USD'}</em>
//           </MenuItem>
//           <MenuItem value="KRW">
//             <em>{isLocaleKorean ? '원' : 'KRW'}</em>
//           </MenuItem>
//         </Select>
//         <TextField
//           style={{ backgroundColor: 'white' }}
//           inputProps={{
//             min: 0,
//             style: { textAlign: 'end' },
//             inputMode: 'decimal',
//             pattern: '^(d*.?d*)$',
//           }}
//           InputProps={{
//             endAdornment: (
//               <InputAdornment position="end">
//                 {portfolio.currency === 'KRW' ? '원' : '$'}
//               </InputAdornment>
//             ),
//             inputComponent: NumericFormatCustom as any,
//           }}
//           value={portfolio.averagePrice}
//           variant="outlined"
//           sx={{ flex: 1 }}
//           onChange={(e) => {
//             const inputValue = e.target.value;
//             if (regex.test(inputValue) || inputValue === '') {
//               onUpdateAveragePrice(Number(inputValue));
//             }
//           }}
//         />
//       </Stack>
//     </Stack>
//   );
// }

// function EmailInput({
//   isLocaleKorean,
//   name,
//   setName,
//   email,
//   setEmail,
//   isValidEmail,
//   setIsValidEmail,
//   phoneNumber,
//   setPhoneNumber,
// }: {
//   isLocaleKorean: boolean;
//   name: string;
//   setName: (name: string) => void;
//   email: string;
//   setEmail: (email: string) => void;
//   isValidEmail: boolean;
//   setIsValidEmail: (valid: boolean) => void;
//   phoneNumber: string;
//   setPhoneNumber: (phoneNumber: string) => void;
// }) {
//   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//   return (
//     <Stack>
//       <Box bgcolor="white" marginBottom="12px">
//         <TextField
//           placeholder={isLocaleKorean ? '닉네임' : 'Nickname'}
//           variant="outlined"
//           sx={{ width: '100%' }}
//           value={name}
//           onChange={(e) => {
//             setName(e.target.value);
//           }}
//         />
//       </Box>

//       <Box>
//         {/* <TextField
//           inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
//           placeholder="휴대폰 번호"
//           variant="outlined"
//           value={phoneNumber}
//           onChange={(e) => {
//             const inputValue = e.target.value;
//             const numericValue = inputValue.replace(/\D/g, '');

//             setPhoneNumber(numericValue);
//           }}
//           sx={{ width: '100%' }}
//           // eslint-disable-next-line
//           InputProps={{
//             inputComponent: TextMaskCustom as any,
//           }}
//         /> */}

//         <TextField
//           placeholder={isLocaleKorean ? '이메일 주소' : 'Email address'}
//           variant="outlined"
//           value={email}
//           onChange={(e) => {
//             const inputValue = e.target.value;
//             setIsValidEmail(emailRegex.test(inputValue));
//             setEmail(inputValue);
//           }}
//           onPaste={(e) => {
//             const inputValue = e.clipboardData.getData('text');
//             setIsValidEmail(emailRegex.test(inputValue));
//           }}
//           sx={{ width: '100%', marginTop: '8px', background: 'white' }}
//         />
//         {email && !isValidEmail && (
//           <Typography style={{ color: 'red', padding: '4px' }}>
//             {isLocaleKorean
//               ? '이메일 형식을 확인해주세요'
//               : 'Please enter an email address'}
//           </Typography>
//         )}
//       </Box>
//     </Stack>
//   );
// }

// interface CustomProps {
//   onChange: (event: { target: { name: string; value: string } }) => void;
//   name: string;
// }

// const NumericFormatCustom = React.forwardRef<NumericFormatProps, CustomProps>(
//   function NumericFormatCustom(props, ref) {
//     const { onChange, ...other } = props;

//     return (
//       <NumericFormat
//         {...other}
//         getInputRef={ref}
//         onValueChange={(values) => {
//           onChange({
//             target: {
//               name: props.name,
//               value: values.value,
//             },
//           });
//         }}
//         thousandSeparator
//         valueIsNumericString
//       />
//     );
//   },
// );
// const TextMaskCustom = React.forwardRef<HTMLElement, CustomProps>(
//   function TextMaskCustom(props, ref) {
//     const { onChange, ...other } = props;
//     return (
//       <IMaskInput
//         {...other}
//         mask="000-0000-0000"
//         // definitions={{
//         //   '#': /[1-9]/,
//         // }}
//         // inputRef={ref}
//         onAccept={(value: any) =>
//           onChange({ target: { name: props.name, value } })
//         }
//         overwrite
//       />
//     );
//   },
// );

export default EventPage;
