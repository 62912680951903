import { initializeApp, FirebaseApp } from 'firebase/app';
// import { getMessaging, getToken, onMessage } from 'firebase/messaging';
// import { onBackgroundMessage } from 'firebase/messaging/sw';
import { getStorage } from 'firebase/storage';
import { getFirestore } from 'firebase/firestore';
import { getAnalytics, logEvent } from 'firebase/analytics';

const firebaseConfig = {
  apiKey: 'AIzaSyBn06k1p1KXekM-eibMcVNRq5ApViZTIjk',
  authDomain: 'tailor-event.firebaseapp.com',
  projectId: 'tailor-event',
  storageBucket: 'tailor-event.appspot.com',
  messagingSenderId: '1060091451871',
  appId: '1:1060091451871:web:936ad33e08c1ee2303cc6a',
  measurementId: 'G-HRVFNRH5TW',
};

const app: FirebaseApp = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const firestore = getFirestore(app);
export const analytics = getAnalytics(app);
