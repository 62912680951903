import * as React from 'react';
import { twj } from 'tw-to-css';

export default function Template() {
  return (
    <body style={twj('font-sans text-md bg-white py-4')}>
      <h1 style={twj('text-black text-center p-0 my-2 mx-0')}>
        Tailwind to CSS!
      </h1>
      <p style={twj('text-gray-400 text-center')}>
        Transform Tailwind classes to pure CSS
      </p>
    </body>
  );
}
